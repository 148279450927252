import React from "react"
import "./css/information.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import _ from "lodash"

export default ({ info, children, title }) => (
    <section className="information-wrapper">
        <div className="information-body">
            <AniLink to="/" cover direction="right" bg="#f1f1f1">
                <div className="name">Philipp Kaltofen</div>
            </AniLink>
            <div className="content">
                { children }
            </div>
        </div>        
        <AniLink swipe duration={0.2} direction="right" bg="#f1f1f1" to={'/' + title}>
            <div className="close">
                <i className="material-icons">
                    close
                </i>
            </div>
        </AniLink>
    </section>
)