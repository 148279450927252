import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from "gatsby"
import "./css/project.css"

class Info extends React.Component {
    render() {
        
        let team
        if(this.props.team != undefined || this.props.team != null){
            team = this.props.team.toString().split(',')
        } else {
            team = ['']
        }

        let links
        if(this.props.links != undefined || this.links.team != null){
            links = this.props.links.toString().split(',')
        } else {
            links = ['']
        }

        // Links
        const LinksRender = ({links}) => (
            <>
                {links.map(link => (
                    <div className="link" key={link}><a href={link}>{link.replace(/(^\w+:|^)\/\//, '')}</a></div>
                ))}
            </>
        )

        // Team        
        const TeamRender = ({team}) => (
            <>
                {team.map(teamMember => (
                    <div className="teamMember" key={teamMember}>{teamMember}</div>
                ))}
            </>
        )

        return (
            <div className="info-body">
                <AniLink to="/" cover bg="#f1f1f1" direction="right">
                    <div className="nav">
                        <div className="logo">
                            <h1>Philipp Kaltofen</h1>
                            <h2>Creative Technologist</h2>
                        </div>
                        <div className="material-icons">
                           close
                        </div>
                    </div>
                </AniLink>
                <div className="info-box">
                    <div className="border">
                    </div>
                    <div className="title">
                        { this.props.title }
                    </div>
                    <div className="type">
                        { this.props.type }
                    </div>
                    <div className="year">
                        { this.props.year }
                    </div>
                    <div className="headline">
                        Roles
                    </div>                    
                    <div className="roles">
                        { this.props.roles }
                    </div>                    
                    <div className="headline">
                        Tags
                    </div>
                    <div className="tags">
                        { this.props.tags }
                    </div>   
                    
                    <div className="headline">
                        team
                    </div>
                    <div className="team">
                        <TeamRender team={team}/>
                    </div>
                    <div className="headline">
                        Links
                    </div>
                    <div className="links">
                        <LinksRender links={links} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Info