import React from "react"
import { Link, graphql } from "gatsby"

import InformationComp from "../components/informationComp"
import _ from "lodash"
import Meta from "../components/meta";
import Info from "../components/info";

class Information extends React.Component {
  render() {        
    const data = this.props.data.allContentfulProject
    const project = data.edges[0].node
    const info  = <Info author="Philipp Kaltofen" links={project.infoLinks} title={project.title} roles={project.infoRoles} team={project.infoTeam} type={project.info} year={project.infoYear} tags={project.infoTags}></Info>

    return (
      <InformationComp title={project.title}>
        <Meta></Meta>
        { info }
      </InformationComp>
    )
  }
}

export default Information

export const pageQuery = graphql`
query informationQuery($title: String!){
  allContentfulProject(filter:{title:{eq:$title}}) {
    edges {
      node {
        title
        subtitle
        infoYear
        info
        infoTags      
        infoRoles  
        infoLinks
        infoTeam
      }
    }
  }
}

`